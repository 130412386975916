import React from "react"

import { Section, Columns } from "../../components/Containers"
import { Text, Image } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"

function FirstTime(props) {
  return (
    <Section
      colorBack={props.colorBack}
      zeroTop
      zeroBottomMobile
      className={`first-time ${props.colorBack ? "color-back--light" : ""}`}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="first-time__grid">
            <div className="first-time__image">
              <Image publicId={"UTOS/DEV/dr-shawn-davis-firt-visit"} />
            </div>
            <div
              className="first-time__card"
              data-aos="fade-up"
              data-aos-duration="1200">
              <Text
                as="h3"
                className="has-text-centered-touch mt-0"
                style={{ marginBottom: "16px" }}
                text={props.post.firstTimePatient.heading}
              />
              <Text
                as="p"
                className="has-text-centered-touch"
                text={props.post.firstTimePatient.text}
              />
              <ButtonGroupMap
                buttons={props.post.firstTimePatient.buttons}
                isCenteredMobile
                noReverse={props.noReverse}
              />
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default FirstTime
