import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import ReviewCard from "./ReviewCard"

const ReviewCarousel = () => {
  const { reviews } = useStaticQuery(
    graphql`
      query {
        reviews: allGoogleReviewsJson {
          nodes {
            rating
            text
            username
          }
        }
      }
    `
  )

  const CustomArrow = props => {
    const { className, style, onClick, isRight } = props
    return (
      <FontAwesomeIcon
        icon={isRight ? faChevronRight : faChevronLeft}
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    className: "",
    accessibility: true,
    adaptiveHeight: true,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    focusOnSelect: false,
    draggable: false,
    arrows: true,
    speed: 500,
    nextArrow: <CustomArrow isRight />,
    prevArrow: <CustomArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  }
  return (
    <div className="review-group">
      <Slider {...settings}>
        {reviews.nodes.map(rev => (
          <ReviewCard key={rev.username} review={rev} />
        ))}
      </Slider>
    </div>
  )
}

export default ReviewCarousel
