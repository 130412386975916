import React from "react"
import star from "./star.svg"

const ReviewCard = ({ review }) => {
  return (
    <div className="review-card">
      <h5 className="review-card__name mt-0 mb-0">{review.username}</h5>
      <div className="review-card__stars">
        <img src={star} alt="star icon" className="review-card__star" />
        <img src={star} alt="star icon" className="review-card__star" />
        <img src={star} alt="star icon" className="review-card__star" />
        <img src={star} alt="star icon" className="review-card__star" />
        <img src={star} alt="star icon" className="review-card__star" />
      </div>
      <div className="review-card__quote-container">
        <p className="review-card__quote">{`"${review.text}"`}</p>
      </div>
    </div>
  )
}

export default ReviewCard
