import React from "react"
import Logo from "../../images/Google__G__Logo.svg"
import "./_Badge.scss"

const Badge = ({ url }) => {
  return (
    <a
      href={url}
      target="_blank"
      aria-label="open link in new browser tab"
      title="Leave us a review"
      className="g-badge">
      <div className="g-badge__logo">
        <img src={Logo} alt="google logo" role="presentation" />
      </div>
      <div className="g-badge__text">Leave us a Google Review!</div>
    </a>
  )
}

export default Badge
